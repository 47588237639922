<template>
  <div class="" id="participant_profile">
    <div class="container-fluid">
      <div class="row header-bg">
        <div class="col-sm-12 header pt-3 pb-3">
          <img :src="avatar" class="img-thumbnail rounded-circle mb-1" alt="">
          <h3 class="fw-bolder">{{participantInfo.name}}</h3>
          <p>
<!--            <strong>District:</strong>  {{participantInfo.district.name}}, -->
            <strong>Region:</strong>  {{participantInfo.division.name}},
            <strong>School:</strong> {{participantInfo.school}}, <strong>Class:</strong> {{participantInfo.class_name.code}}, <strong>Category:</strong> {{participantInfo.categories[0].code}}  <br>
            <strong>Reg. No:</strong> {{participantInfo.reg_no}}, <strong>Password:</strong> {{participantInfo.password}}, <strong>Mobile:</strong> {{participantInfo.mobile}}
          </p>
          <div id="timer" v-if="examTimer.showExamMessage">
            <span class="timer">{{examTimer.showExamMessage}}</span>
          </div>
          <router-link :to="{name:'EditParticipantInfo'}" class="btn btn-sm btn-success me-2 fw-bold"><i class="fa fa-edit"></i> Update Info</router-link>
          <a href="https://admin.bdpho.org/admit-card" target="_blank" class="btn btn-sm btn-primary me-2 fw-bold"><i class="fa fa-download"></i> Admit Card</a>
          <router-link :to="{name:'AdmitDownload', participant_id:participantInfo.id}" v-if="selected" class="btn btn-sm btn-primary me-2 fw-bold"><i class="fa fa-download"></i> Admit Card</router-link>

<!--          <a target="_blank" :href="certificateLink" class="btn btn-sm btn-info me-2 fw-bold"><i class="fa fa-download"></i> Download Certificate <i class="fa fa-file-text"></i></a>-->
<!--          <router-link :to="{name:'AdmitDownloadNew', participant_id:participantInfo.id}" v-if="selected" class="btn btn-sm btn-warning me-2 fw-bold"><i class="fa fa-download"></i> Admit Card New</router-link>-->
          <button v-if="examTimer.showExamMessage" class="btn btn-sm btn-primary fw-bold" @click="startExam"><i class="fa fa-hourglass-start"></i> Start Exam</button>
        </div>
      </div>

      <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column bd-highlight pt-3 pb-3">
        <div class="p-2 w-100 bd-highlight bg-success basic-info rounded mb-sm-3 mb-md-0">
          <div class="card text-white bg-success mb-3 border-0" style="max-width: 100%;">
            <div class="card-header"><h5 class="card-title">Basic Info</h5></div>
            <div class="card-body">
              <p><strong>Name: </strong>{{participantInfo.name}} {{participantInfo.last_name}}</p>
              <p><strong>School: </strong>{{participantInfo.school}}</p>
              <p><strong>Class: </strong>{{participantInfo.class_name.code}}</p>
              <p><strong>Category: </strong>{{participantInfo.categories[0].code}}</p>
<!--              <p><strong>District: </strong>{{participantInfo.district.name}}</p>-->
              <p><strong>Reg. No: </strong>{{participantInfo.reg_no}}</p>
              <p><strong>Region: </strong>{{participantInfo.division.name}}</p>
              <p><strong>Password: </strong>{{participantInfo.password}}</p>
              <p><strong>Mobile. No: </strong>{{participantInfo.mobile}}</p>
              <p><strong>email: </strong>{{participantInfo.email}}</p>
<!--              <p><strong>Post-code: </strong>{{participantInfo.post_code}}</p>-->
              <p><strong>Address: </strong>{{participantInfo.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ParticipantProfile",
    data(){
      return {
        participantInfo:null,
        avatar: localStorage.getItem('avatar'),
        questionPaper:null,
        examTimer:{
          firstExamStartTime:null, firstExamStartReadableTime:null,
          lastExamStartTime:null, lastExamStartReadableTime:null,
          currentTime:null, currentReadableTime:null,
          timeLeftToStartExam:null, lastTimeToStartExam:null,
          examMinutes:null, examSeconds:null,
          showExamTime:null, showExamMessage:null,
          timerControl:null,
        },
        questionPaperPublished:false,
        questionPaperClose:false,
        selected: false,
        certified:false,
        certificateLink:null
      }
    },
    created(){
      this.participantInfo = JSON.parse(localStorage.getItem('participant'));
      //Redirect to login form
      if (this.participantInfo === null){this.$router.push({name:'Login'});}

      //Checking Question Paper
      this.$http.post('get-question-paper', {
        id: this.participantInfo.id,
        reg_no: this.participantInfo.reg_no,
        mobile: this.participantInfo.mobile,
        category_id: this.participantInfo.categories[0].id,
      }).then(response => {
        if (response.body.status){
          this.questionPaper = response.body.qp;
          this.examTimer.firstExamStartReadableTime = response.body.qp.exam_start_time;
          this.examTimer.lastExamStartReadableTime = response.body.qp.exam_end_time;
          this.examTimer.timerControl = window.setInterval(this.setTimer,1000);
          // this.examTimer.timerControl = window.setInterval(this.myExamTimer,1000);
        }
      });

      this.$http.post('get-admit-card', {
        id: JSON.parse(localStorage.getItem('participant')).id,
        reg_no: JSON.parse(localStorage.getItem('participant')).reg_no,
      }).then(response => {
        this.selected = response.body;
      });

      this.$http.post('get-certificate', {
        id: JSON.parse(localStorage.getItem('participant')).id,
        reg_no: JSON.parse(localStorage.getItem('participant')).reg_no,
      }).then(response => {
        console.log(response.body);
        this.certificateLink = response.body;
      });
    },
    updated(){

    },
    mounted(){

    },
    methods:{
      cancelRegistration(){
        return confirm('If you want to cancel your registration, press OK or press Cancel');
      },

      startExam(){

        if (this.questionPaperPublished){
          this.$router.push({name:'StartExam'});
        }else{
          alert('Please wait . . . . .');
        }
      },

      myExamTimer(){
        this.examTimer.currentTime = new Date().getTime();
        //Times coming from question paper
        this.examTimer.firstExamStartTime = new Date(this.examTimer.firstExamStartReadableTime).getTime();
        this.examTimer.lastExamStartTime = new Date(this.examTimer.lastExamStartReadableTime).getTime();
        //Times counting upto last time of starting exam
        this.examTimer.timeLeftToStartExam = (this.examTimer.firstExamStartTime - this.examTimer.currentTime);
        this.examTimer.lastTimeToStartExam = (this.examTimer.lastExamStartTime - this.examTimer.currentTime);

        if (this.examTimer.timeLeftToStartExam>0){
          //Counting minutes and seconds to start exam
          this.examTimer.examMinutes = Math.floor((this.examTimer.timeLeftToStartExam % (1000 * 60 * 60)) / (1000 * 60));
          this.examTimer.examSeconds = Math.floor((this.examTimer.timeLeftToStartExam % (1000 * 60)) / 1000);
          this.examTimer.showExamTime = this.examTimer.examMinutes+' : '+this.examTimer.examSeconds;
          this.examTimer.showExamMessage = 'Time Left to Start Exam: '+this.examTimer.showExamTime;
        } else if (this.examTimer.timeLeftToStartExam<0 && this.examTimer.lastTimeToStartExam>0){
          //Counting minutes and seconds to close the exam
          this.examTimer.examMinutes = Math.floor((this.examTimer.lastTimeToStartExam % (1000 * 60 * 60)) / (1000 * 60));
          this.examTimer.examSeconds = Math.floor((this.examTimer.lastTimeToStartExam % (1000 * 60)) / 1000);
          this.examTimer.showExamTime = this.examTimer.examMinutes+' : '+this.examTimer.examSeconds;
          this.examTimer.showExamMessage = 'Time Left to close Exam: '+this.examTimer.showExamTime;
        }else {
          this.examTimer.showExamMessage = null;
        }
      },

      setTimer(){
        let timeObject = null;
        timeObject = this.getCountdownValues(this.examTimer.firstExamStartReadableTime);
        if (timeObject.hours>0 || timeObject.minutes>0 || timeObject.seconds>0){
          this.examTimer.showExamMessage = 'Time Left To Start: '+timeObject.hours+':'+timeObject.minutes+':'+timeObject.seconds;
        }else {
          timeObject = this.getCountdownValues(this.examTimer.lastExamStartReadableTime);
          if (timeObject.hours>0 || timeObject.minutes>0 || timeObject.seconds>0){
            this.questionPaperPublished = true;
            this.examTimer.showExamMessage = 'Time Left To Close: '+timeObject.hours+':'+timeObject.minutes+':'+timeObject.seconds;
          }else {
            this.questionPaperPublished = false;
          }
        }
      },

      getCountdownValues: function( eventDate ) {
        let currentTimeStamp, now, parseDate, timeDifference, convertMilliseconds;
        now = new Date();
        parseDate = function( eDate ) {
          let eventDateParts = eDate.split( /\D/ ),
            dateParts = [], date, i;
          for ( i = 0; i <= eventDateParts.length; i++ ) {
            dateParts[ i ] = parseInt( eventDateParts[ i ], 10 );
          }
          date = new Date( Date.UTC( dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5] ) );
          return date.valueOf();
        };

        currentTimeStamp = ( new Date( Date.UTC ( now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds() ) ) ).valueOf();
        convertMilliseconds = function( ms ) {
          let d, h, m, s, padNumber;
          s = Math.floor( (ms-21600000) / 1000 );
          m = Math.floor( s / 60 );
          h = Math.floor( m / 60 );
          d = Math.floor( h / 24 );
          padNumber = function( n ) {
            return n < 10 ? '0' + String( n ) : n;
          };
          return {
            total: ms,
            days: d,
            hours: padNumber( h % 24 ),
            minutes: padNumber( m % 60 ),
            seconds: padNumber( s % 60 ),
          };
        };
        timeDifference = parseDate( eventDate ) - currentTimeStamp;
        return convertMilliseconds( timeDifference );
      }
    }
  }
</script>

<style scoped>
  .header-bg{
    background-image: url("../assets/site_images/bg.jpg");
    background-position: center;
  }
  .header{
    color: #ffffff;
    background-color: rgba(33,37,41,0.8);
  }
  .header img{
    vertical-align: middle;
    width: 150px;
  }
  .basic-info{
    font-family: Rockwell;
    text-align: left;
  }

  .basic-info p{
    margin-bottom: 0;
  }
  .basic-info p:last-child{
    margin-bottom: 10px;
  }

  .timer {
    display: inline-block;
    font-family: Rockwell;
    font-size: 20px;
    font-weight: bold;
    background: gray;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 0.50em;
  }
</style>
